import React from 'react'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import Header from '../components/header'
import Columns from '../components/columns'
import Sidebar from '../components/sidebar'
import Quote from '../components/quote'
import FinancialPlanningNav from '../components/financial-planning-nav'

const image = require('../images/dt_cropped.png')

const AboutPage = () => (
  <Layout>
    <Helmet
      title={'Financial Planning'}
      meta={[
        {
          name: 'description',
          content:
            // tslint:disable-next-line:max-line-length
            'Choosing a financial partner is about far more than just finances. Here at Adage Financial, we take pride in the options and services we offer our clients.',
        },
        {
          name: 'keywords',
          content:
            // tslint:disable-next-line:max-line-length
            'Adage Financial Consultants, David Templeton, Little Rock Arkansas, Financial Advisor, Financial Planner, 401k, Safe Money, Annuity',
        },
      ]}
    />
    <Header headline={'Financial Planning'} image={image} caption />
    <Columns background>
      <Sidebar>
        <FinancialPlanningNav />
      </Sidebar>
      <div className="marketing__column">
        <Quote
          text={
            // tslint:disable-next-line:max-line-length
            'Adage Financial Consultants offers a diverse yet intentional set of financial planning services.  See our sections below to see how we can help you.'
          }
        />
        <div className="marketing__callout__container">
          <a
            target="_blank"
            href="https://www.401kmaneuver.com/david-templeton-lp"
            className="marketing__callout"
          >
            <h2>401k Maneuver Program</h2>
          </a>
          <Link
            className="marketing__callout"
            activeClassName="sidebar--active"
            to="/financial-planning/investment-services"
          >
            <h2>Investment Services</h2>
          </Link>
          <Link
            className="marketing__callout"
            activeClassName="sidebar--active"
            to="/financial-planning/retirement-planning"
          >
            <h2>Retirement Planning</h2>
          </Link>
          <Link
            className="marketing__callout"
            activeClassName="sidebar--active"
            to="/financial-planning/risk-protection"
          >
            <h2> Risk Protection</h2>
          </Link>
          <Link
            activeClassName="sidebar--active"
            to="/financial-planning/safe-money"
            className="marketing__callout"
          >
            <h2>Safe Money</h2>
          </Link>
        </div>
      </div>
    </Columns>
  </Layout>
)

export default AboutPage
